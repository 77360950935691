{
    "carMakes": [
        "AC",
        "AM General",
        "AMC",
        "Abarth",
        "Acura",
        "Alfa Romeo",
        "Allard",
        "Alpina",
        "Alpine",
        "Alvis",
        "Am General",
        "American Motors",
        "Armstrong Siddeley",
        "Aston Martin",
        "Auburn",
        "Audi",
        "Austin",
        "Austin-Healey",
        "Autobianchi",
        "Avanti",
        "Avanti Motors",
        "BMW",
        "Beijing",
        "Bentley",
        "Berkeley",
        "Bitter",
        "Bizzarrini",
        "Bristol",
        "Bugatti",
        "Buick",
        "Cadillac",
        "Caterham",
        "Checker",
        "Chevrolet",
        "Chrysler",
        "Citroen",
        "DAF",
        "DKW",
        "Daewoo",
        "Daihatsu",
        "Daimler",
        "Datsun",
        "De Tomaso",
        "DeTomaso",
        "Delahaye",
        "Delorean",
        "Desoto",
        "Dodge",
        "Donkervoort",
        "Eagle",
        "Edsel",
        "Essex",
        "FIAT",
        "Fairthorpe",
        "Ferrari",
        "Fiat",
        "Fisker",
        "Ford",
        "Franklin",
        "GAZ",
        "GMC",
        "Genesis",
        "Geo",
        "Ginetta",
        "HUMMER",
        "Holden",
        "Honda",
        "Hudson",
        "Humber",
        "Hummer",
        "Hupmobile",
        "Hyundai",
        "INFINITI",
        "Infiniti",
        "Innocenti",
        "International",
        "Isuzu",
        "Italdesign",
        "Jaguar",
        "Jeep",
        "Jensen",
        "Kaiser",
        "Kia",
        "LaSalle",
        "Lada",
        "Lamborghini",
        "Lancia",
        "Land Rover",
        "Lexus",
        "Lincoln",
        "Lotus",
        "MG",
        "MINI",
        "Marcos",
        "Maserati",
        "Matra-Simca",
        "Maybach",
        "Mazda",
        "McLaren",
        "Mercedes-Benz",
        "Mercury",
        "Merkur",
        "Mini",
        "Mitsubishi",
        "Monteverdi",
        "Moretti",
        "Morgan",
        "Morris",
        "NSU",
        "Nash",
        "Nissan",
        "Oldsmobile",
        "Opel",
        "Packard",
        "Panoz",
        "Peugeot",
        "Pininfarina",
        "Plymouth",
        "Polestar",
        "Pontiac",
        "Porsche",
        "Proton",
        "RAM",
        "Reliant",
        "Renault",
        "Riley",
        "Rolls-Royce",
        "Rover",
        "Saab",
        "Saturn",
        "Scion",
        "Seat",
        "Simca",
        "Singer",
        "Skoda",
        "Sterling",
        "Steyr",
        "Studebaker",
        "Subaru",
        "Sunbeam",
        "Suzuki",
        "TVR",
        "Talbot",
        "Tatra",
        "Tesla",
        "Toyota",
        "Trabant",
        "Triumph",
        "Vauxhall",
        "Venturi",
        "Volkswagen",
        "Volvo",
        "Wartburg",
        "Westfield",
        "Willys",
        "Willys-Overland",
        "Yugo",
        "ZAZ",
        "ZIL",
        "Zagato",
        "Zastava",
        "smart"
    ],
    "motorcycleMakes": [
        "American eagle",
        "Aprilia",
        "Aro",
        "ATK",
        "Big dog",
        "BMW",
        "Bombardier",
        "BSA motorcycle",
        "Buell",
        "Cagiva",
        "Cobra Trike",
        "Dacia",
        "Derbi",
        "Dinli",
        "Ducati",
        "Ford",
        "Harley-Davidson",
        "Honda",
        "Husaberg",
        "Husqvarna",
        "Hyos",
        "Indian",
        "Isuzu",
        "Fahrzeugwerke",
        "Kawasaki",
        "KTM",
        "Maxim",
        "Mitsubishi",
        "Moto guzzi",
        "Mototadwerk",
        "Other Motorcycle",
        "Polaris",
        "Shenke",
        "Suzuki",
        "Triumph",
        "Ural",
        "Victory",
        "Yamaha"
    ],
    "boatMakes": [
        "Abaco",
        "Abbott Boats Ltd",
        "Academy Yachts/Canyon Tsf",
        "Achilles Inflatables",
        "Action Craft",
        "Action Marine",
        "Active Thunder Boats",
        "Addictor Boats",
        "Advantage",
        "Adventure Craft",
        "Adventurer Ii/Jim-Bar Co",
        "Advanced Yachts Inc",
        "Aerofino",
        "Aerocraft Boats",
        "Aero-Tek High Performance",
        "Aftershock Power Boats Inc",
        "A1a Nautical Inc",
        "Aicon",
        "Air Commander",
        "Air Cushion Concepts",
        "Albin",
        "Albemarle",
        "Albury Brothers",
        "Aluminum Chambered Boats",
        "Alcort Sailboats",
        "Alden Yachts",
        "Alerion-Express/Tpi Inc",
        "A & L Fiberglass",
        "Allen Boat Co",
        "All Family Craft/Charger",
        "Allison",
        "Allison Marine Inc",
        "Almar Aluminum Marine",
        "Alumacraft",
        "Alumitech Inc",
        "Aluma-Weld/Xpress",
        "Aloha Pontoon Boats",
        "Alsberg Boat Works Inc",
        "Aluminum",
        "Alura/Luhrs Corp",
        "Alumaweld",
        "American Cruising Yacht Co",
        "American Hydro Ski",
        "American Offshore",
        "Americam Redi Bilt",
        "American River Mfg",
        "American Skiff",
        "American Sail Inc",
        "Anacapri Marine",
        "Angler",
        "Answer",
        "Apache",
        "Apache Seamaster",
        "Apex",
        "Aprea Mare",
        "Apriva",
        "Aquarius Boats",
        "Aquarius Sails",
        "Aqua-Craft Boat Co",
        "Aqua Chalet",
        "Aqua Cruiser",
        "Aqua Force",
        "Aquajet Corporation",
        "Aqua Patio/Godfrey Marine",
        "Aquapro/Fastrac",
        "Aqua Star",
        "Aquatron",
        "Aquasport",
        "Aries",
        "Arima Marine",
        "Armada Enterprises",
        "Arp Corp",
        "Arriva",
        "Arrow Glass/Gmi",
        "Ash Craft Boat Co Inc",
        "Astroglass Boats",
        "Astro Boats",
        "Atlantic",
        "Aurora Pontoon Boats",
        "Avanti By Dials Mfg",
        "Avalon",
        "Avanti Marine Inc",
        "Avance",
        "Avanti Boats/The Jet Set",
        "Avenger",
        "Avon",
        "Awesome Powerboats",
        "Azimut",
        "Aztec Marine",
        "Back Cove",
        "Back Country Powerboats",
        "Baha Cruisers",
        "Baia Of America",
        "Baja",
        "Baltic",
        "Banana Boat Ltd",
        "Bankes Boats",
        "Barry Douglas Ent Inc.",
        "Barnett Boat Co. Inc.",
        "Barracuda Boats Inc.",
        "Barretta Manufacturing Ltd",
        "Bass Cat",
        "Bassmaster",
        "Bass",
        "Bass Stealth/Vip",
        "Bayfield Boatyard Ltd",
        "Bay Craft Inc",
        "Bay Hawk Industries Inc.",
        "Bayliner",
        "Bay Marine",
        "Baysport",
        "Bayquest",
        "Bayshore",
        "Bay Stealth",
        "Baymaster",
        "B & D Boatworks",
        "Beachcomber Boats Inc",
        "Beachcraft Powerboats",
        "Beachcat",
        "Bedouin Boat Mfg",
        "Bell Boats",
        "Benchmark",
        "Beneteau",
        "Bennington Marine",
        "Bentley",
        "Bertram",
        "Bestway Ind",
        "B&H Performance Boats",
        "Bimini Boat Co.",
        "Bimini Marine",
        "Blazer Boats Inc",
        "Blue Fin Yachts, Ltd",
        "Blackman Boats",
        "Blackfin",
        "Black Pearl",
        "Black Thunder Powerboats",
        "Black Watch",
        "Blankdb",
        "Blue Fin Ind/Spectrum",
        "Blue Star",
        "Blue Thunder Boat Mfg",
        "Bluewave",
        "Blue Water",
        "Bluewater Yachts",
        "Blue Water Sport Fishing Bo",
        "Bombardier",
        "Boston Whaler",
        "Boulton Powerboats, Inc",
        "Bravado Boat Company Inc",
        "Bryant Boats Inc",
        "Bass Hunter",
        "Bass Hawk Boat Co.",
        "Bass Max",
        "Basstream",
        "Bass Wacker",
        "Bullet Boats",
        "Bumble Bee",
        "Burger",
        "Cabo",
        "Cabo Rico",
        "Cabo Yachts",
        "Cajun Boats/Mastercrafter",
        "Calabria Genuine Ski Boats",
        "Calba Boats",
        "Calybre",
        "Camano",
        "Campion",
        "Canyon Yachts",
        "Cape Craft Fishing Boats",
        "Cape Dory",
        "Cape Cod Marine",
        "Cape Fear",
        "Cape Horn/Fabbro Marine Gro",
        "Caravelle",
        "Carribean",
        "Carolina Classic",
        "Caribe",
        "Caribiana, Inc",
        "Carolina Boat Works",
        "Carrera",
        "Carolina Skiff",
        "Carver / Trojan",
        "Carver Yachts",
        "Catera",
        "Cavileer",
        "CCB",
        "Catalina Boat",
        "C-Dory",
        "Cee Bee",
        "Celebrity",
        "Century",
        "Centurion Boats",
        "Challenger",
        "Champion",
        "Chaparral",
        "Charger",
        "C-Hawk",
        "Checkmate Boats Inc",
        "Cheoy Lee",
        "Chris Craft",
        "Chrysler",
        "Cigarette Racing Team Inc",
        "Classic Airboats",
        "Clipper Craft",
        "Classic Yacht Inc",
        "Cobia/Robalo",
        "Cobia",
        "Cobalt",
        "Cobra Power Boats",
        "Cobra Performance Boats",
        "Cole Boats",
        "Component Specific",
        "Conch",
        "Concept",
        "Conquest Boats",
        "Contender",
        "Correct Craft",
        "Cougar",
        "Cranchi",
        "Crescent",
        "Crosby",
        "Crownline",
        "Crest-Maurell Products",
        "Crestliner",
        "Cruiser Yachts",
        "Cruisers Yachts",
        "C.W. Hood Yachts",
        "Dakota",
        "Davis Boatworks",
        "Dawson Yachts/J&J Ind",
        "Deep Impact",
        "Defever",
        "Defiance",
        "Delta Boats Inc",
        "Design Concepts",
        "Dettling",
        "Dolphin",
        "Don Smith",
        "Donzi",
        "Doral",
        "Dorado",
        "Duck Boat Co",
        "Duffy",
        "Dura Craft Boats Inc",
        "Duracraft Marine Inc",
        "Duromarine",
        "Dusky",
        "Dusky Marine",
        "Dyer/The Anchorage Inc",
        "Dynatech",
        "Dynasty Boats",
        "Eastbay",
        "Eagle 2",
        "Eagle Boats Inc",
        "Eagle Boats Ltd",
        "Eastern",
        "Ebbtide",
        "Edey & Duff, Ltd",
        "Edgewater",
        "Egg Harbor Yachts",
        "Egret",
        "Elan/American Skier",
        "Eliminator Boats",
        "Ellis",
        "Emerald Boats",
        "Endeavour",
        "Envision Boats Inc",
        "Ercoa",
        "Everglades",
        "Evinrude",
        "Excalibur",
        "Fairline",
        "Falcon",
        "Farallon",
        "Fast Cat",
        "Featherlite",
        "Ferritti",
        "Fineline",
        "Firehawk",
        "Fisher",
        "Fleming Yachts",
        "Florida Skiff",
        "Force",
        "Formula",
        "Fortier",
        "Formula Yachts",
        "Fountain",
        "Four Winns",
        "Freedom Yachts",
        "Fishhawk",
        "FWD",
        "Gambler",
        "Gamefisherman",
        "G3 Boats",
        "Gekko",
        "General Marine Inc",
        "Glacier Bay",
        "Glastron",
        "Gulf Coast Boats",
        "Gulf Master Inc",
        "Gulfstream Boats Inc",
        "Gulfstream Marine Internati",
        "Grady White",
        "Gregor",
        "Grand Banks",
        "Grand Harbour",
        "Gulf Craft Inc.",
        "Guy Couach",
        "Hallett Boats/Barron",
        "Halvorsen",
        "Hampton",
        "Harbercraft/Eagle Power Boa",
        "Harris Floteboat",
        "Hargrave",
        "Harris Kayot",
        "Harley Boat Co",
        "Harbor Master",
        "Hatteras Yachts",
        "Henriques Boat Works",
        "Heritage",
        "Hewes Craft",
        "Hinckley Yachts",
        "Holby",
        "Holiday Mansion",
        "Honda",
        "Horizon",
        "Huckins",
        "Hunter",
        "Hunt",
        "Hurricane/Godfrey Marine",
        "Hustler",
        "Hydrocat",
        "Hydra Sport",
        "Iandau",
        "Imp Boats",
        "Inboard Props",
        "Independence",
        "Infinity",
        "Integrity",
        "Intrepid",
        "Invader",
        "Invader Marine Inc",
        "Island Gypsy",
        "Island Hopper Boats",
        "Island Runner",
        "Islander/M.F.I.",
        "Javelin",
        "J.C. Manufacturing",
        "J.C. Pontoons",
        "Jeanneau",
        "Jefferson Yachts",
        "Jersey Yachts",
        "Johnson",
        "John M. Williams",
        "Jupiter",
        "Kachina Boats",
        "Kadey-Krogen",
        "Kah Shing",
        "Kanter",
        "Kawasaki",
        "Kayot",
        "Kencraft",
        "Kenner",
        "Kevlacat America",
        "Key Largo",
        "Key West Boats",
        "Kingfisher Master Molders",
        "Klamath",
        "Koffler Boats",
        "Krogen Express",
        "Kryptonite",
        "Landau Boat Co",
        "Lars",
        "Lavey Craft",
        "Lazarra",
        "Leader Catamarans",
        "Legacy",
        "Liberty",
        "Liberty Yachts",
        "Lien Hwa",
        "Liesure Cat",
        "Linssen",
        "Little Harbor",
        "Livingston Boats",
        "Lancer Boats",
        "Logic Boats",
        "Lowe",
        "Larson",
        "Luhrs",
        "Lund",
        "Lyman-Morse",
        "Magnum",
        "Mainship",
        "Mako",
        "Malibu",
        "Manitou",
        "Manta Ray",
        "Maranda",
        "Mares International",
        "Mariah",
        "Marlin Yacht",
        "Mariner",
        "Maritime Skiff",
        "Marine Trader",
        "Mastercraft",
        "Maverick Boat Co.",
        "Maxum",
        "Mckee Craft",
        "Mediterranean Yachts",
        "Mercury",
        "Meridian Yachts",
        "Mercruiser",
        "Michigan Wheel Props",
        "Mickelson Yachts Inc",
        "Mirage Manufacturing",
        "Mirro Craft",
        "Monark",
        "Monterey",
        "Nauset Marine",
        "Nautica",
        "Nissan",
        "Nitro",
        "Nordic Tug",
        "Nordhavn",
        "Ocean Yachts",
        "Odyssey",
        "OMC",
        "Omc Outboard",
        "Orca Yachts Llc",
        "Osprey Boat Co Inc",
        "Other Boat",
        "Outboard Props",
        "Out Island",
        "Palm Beach Boats",
        "Palmetto",
        "Panther Airboats",
        "Parker",
        "Parti Craft/Godfrey Marine",
        "Pathfinder Boat Co Inc",
        "Pearson",
        "Penn Yan Boats Inc",
        "Pershing",
        "Phantom Boats Inc",
        "Phoenix Marine",
        "Playbouy",
        "Polar/Godfrey Marine",
        "Polarkraft/Godfrey Marine",
        "Polaris",
        "Post Marine",
        "Power Play",
        "Powerquest",
        "Powerplay Marine Inc",
        "Practicedb",
        "Predator Marine Corp",
        "Prince Craft",
        "Privateer Boats",
        "Procraft",
        "Pro Line Boat Co",
        "Pro Sports",
        "Protector",
        "Pursuit By S2 Yachts Inc",
        "PWC",
        "Rampage Yachts",
        "Ranger",
        "Regal",
        "Regulator",
        "Release Boats Inc",
        "Rinker",
        "Riviera/Edmonds Yacht Sales",
        "Robalo/Us Marine",
        "Rybo Runner",
        "Sail Boat",
        "Sanpan/Godfrey Marine",
        "Scout",
        "Sea Arrow",
        "Seacraft",
        "Sea Doo",
        "Sea Fox",
        "Seagull Boats",
        "Sea Ark",
        "Seamaster Yachts",
        "Sea-Pro Boats Inc",
        "Sea Ray",
        "Seasport/United Marine Corp",
        "Seacraft/Tracker",
        "Seamaster/United Marine Cor",
        "Sea Vee",
        "Seaswirl",
        "Selene Ocean Trawlers",
        "Shamrock",
        "Shasta Alum Pro/Western Boa",
        "Skeeter",
        "Skipjack",
        "Silverhawk",
        "Silverline",
        "Silverton",
        "Smoker Craft",
        "Splendor Boats",
        "Sportcraft Boats",
        "Stamas Yachts",
        "Starcraft",
        "Sterndrive Props",
        "Stingray",
        "Stratos",
        "Stryker Boat Co. Inc",
        "Sugar Sand",
        "Sunbird",
        "Suncruiser",
        "Sundance Boats Inc.",
        "Sundancer Pontoons",
        "Sun Tracker",
        "Suzuki",
        "Sweetwater/Godfrey Marine",
        "Tahoe",
        "Talon Marine",
        "Taylor Boats/Big T",
        "Template For Fiberglass Rep",
        "Thunderbird/Falcon",
        "Thunderbird/Formula",
        "Tiara Sportboats",
        "Tiara Yachts",
        "Tides Boatworks",
        "Tiffany Yachts",
        "Tige",
        "Tohatsu",
        "Topaz Marine",
        "Tracker",
        "Tradewinds",
        "Triton",
        "Triumph",
        "Trojan",
        "Trophy",
        "TVR",
        "Twin Vee",
        "Venture Marine Inc",
        "Vip",
        "Volvo Penta",
        "Volvo",
        "Wellcraft",
        "White Water Boats",
        "Wiegel Brothers Marine",
        "X-Press",
        "Yamaha",
        "Zodiac"
    ],
    "RVMakes": [
        "Aeroliner",
        "Airstream",
        "Aluma-lite",
        "American By Cobra",
        "American Cruiser",
        "American Dream",
        "American Eagle",
        "American Mobile Traveler",
        "American Star",
        "American Tradition",
        "American Travel Systems",
        "Aro",
        "Auto Mate",
        "Camper",
        "Chevrolet",
        "Chinook",
        "Coach",
        "Coachmen",
        "Cobra",
        "Coleman",
        "Crossroads",
        "Dodge",
        "Ducati",
        "Dutch Star",
        "Dutchmen",
        "Eldorado",
        "Featherlite",
        "Fleetwood",
        "Ford",
        "Forest River",
        "Four Wheel",
        "Four Winds",
        "Gillig",
        "Gmc",
        "Gulf Stream",
        "Gulfstream",
        "Hino",
        "Holiday",
        "Itasco",
        "Iveco",
        "Jaycee",
        "Jayco",
        "Keystone Challenger",
        "King Of The Road",
        "Kit",
        "Komfort",
        "Lance",
        "Layton",
        "Lazy Daze",
        "Lincoln Townhouse",
        "Mci (les Auto Bus)",
        "Monoco",
        "Mototadwerk",
        "Mountain Air",
        "New Flyer",
        "Nissan Diesel",
        "Nomad",
        "Northstar",
        "Nuwa",
        "Oshkosh",
        "Other Rv",
        "Pace",
        "Polaris",
        "Prevost",
        "Prowler",
        "Puma",
        "Roadmaster Rail",
        "Safari",
        "Shasta",
        "Spartan",
        "Starcraft",
        "Sunline",
        "Thor",
        "Trail-lite",
        "Trailmanor",
        "Travel Line Enterprises",
        "Travel Supreme",
        "Travelmaster",
        "Travlite",
        "Tvr",
        "Van Hool",
        "Weekend Warrior",
        "West Coast",
        "Wildcat",
        "Wilderness",
        "Winnebago",
        "Workhorse"
    ]
}